import { Routes, Route } from 'react-router-dom'
import Home from './components/home';
import Appbar from './components/appbar';
import Footer from './components/footer';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Appbar></Appbar>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer></Footer>
      </ThemeProvider>
      
    </div>
  );
}

export default App;
