import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/logo.png';

export default function Appbar() {
  return (
    <Box>
        <AppBar className="App-header" position="static" color="primary">
            <Container>
                <Toolbar variant="roth">
                    <img src={logo} className="logo" alt=""></img>
                    <Box></Box>
                </Toolbar>
                
            </Container>
        </AppBar>
    </Box>
  );
}