import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import logo from '../assets/roth-logo-m.png';

export default function Footer() {
  return (
    <Box mt={10} className='footer-wrapper'>
        <AppBar className="App-header" position="static" color="primary">
            <Container>
                <Grid container spacing={2} py={5}>
                    <Grid item xs={12} md={3}>
                        <img src={logo} className="logo" alt=""></img>
                    </Grid>
                    <Grid item className="footer-text-wrapper" xs={12} md={6} sx={{display: "flex", alignItems: "center"}}>
                        <Typography className="footer-text" variant="p" component="p" mb={3} sx={{textAlign: "left"}}>    
                            Roth Absturzsicherungen<br></br>
                            Am Käppele 6<br></br>
                            74676 Niedernhall-Waldzimmern<br></br>
                            <a className="link" href="tel:+4979409053030">07940 9053030</a><br></br>
                            <a className="link" href="mailto:info@roth-absturzsicherungen.de">info@roth-absturzsicherungen.de</a>
                        </Typography>
                    </Grid>
                    <Grid item  className="footer-links-wrapper" xs={12} md={3} sx={{display: "flex", alignItems: "center"}}>
                        <Typography className="footer-links" variant="p" component="p" mb={3} sx={{textAlign: "right"}}>
                            <a className="link" href="https://roth-absturzsicherungen.de/kontakt/impressum/">IMPRESSUM</a><br></br>
                            <a className="link" href="https://roth-absturzsicherungen.de/kontakt/datenschutzerklaerung/">DATENSCHUTZERKLÄRUNG</a><br></br>
                            <a className="link" href="https://roth-absturzsicherungen.de/kontakt/agb/">AGB</a><br></br>
                            <a className="link" href="https://roth-absturzsicherungen.de/#gdpr_cookie_modal">COOKIES</a><br></br>
                        </Typography>
                    </Grid>
                </Grid>
                
            </Container>
        </AppBar>
    </Box>
  );
}