import React, {useEffect, useState} from 'react';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import queryString from "query-string"

const Home = () => {
    // Declare States
    const [location, setStateLocation] = useState([]);
    const setLocation = (event) => { setStateLocation(event.target.value);};

    const [docType, setStateDocType] = useState([]);
    const setDocType = (event) => { setStateDocType(event.target.value);};

    const [filelist, setStateFilelist] = useState([]);
    const setFilelist = (x) => {setStateFilelist(x);};

    const [locationList, setStateLocationList] = useState([]);
    const setLocationList = (x) => {setStateLocationList(x);};

    const [docTypeList, setStateDocTypeList] = useState([]);
    const setDocTypeList = (x) => {setStateDocTypeList(x);};

    const [errorMsg, setStateErrorMsg] = useState([]);
    const setErrorMsg = (x) => {setStateErrorMsg(x);};

    
    const [token, _setStateToken] = useState(null);
    const tokenRef = React.useRef(token);
    const setStateToken = x => {
        tokenRef.current = x;
        _setStateToken(x);
        SPfetchLocation();
    };

    //Get URL Parameter
    const queryParams = queryString.parse(window.location.search)
    const param_company = encodeURIComponent(queryParams.company);
    const param_location = encodeURIComponent(queryParams.location);
    const param_id = encodeURIComponent(queryParams.id);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let param_pass = '';

    if (params.has('password')){
         param_pass = queryParams.password;
    } else {
        param_pass = queryParams.p;
    }


    // M365 Parameters
    const SPTenant = 'rothabsturzsicherung'
    const SPSiteCollection = 'Dachdoku';
    const SPList = 'Doku';

    const  GetAccessToken = async () => {
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("accept", "*/*")
            myHeaders.append("Access-Control-Allow-Origin", "*")

        var requestOptions = {
            method: 'Get',
            headers: myHeaders,
            redirect: 'follow'
            };

        //await fetch("http://localhost:3001/roth/dachdoku/createToken?pass=" + param_pass, requestOptions).then((response) => response.json())
        await fetch("https://api.unavio.de/roth/dachdoku/createToken?pass=" + param_pass, requestOptions).then((response) => response.json())
        .then((responseToken) => {
            setStateToken(responseToken)
        }).catch((error) => {
        });

                 
    }
    
    //create a function to fetch the data
    const SPfetchLocation = async() => {
        var $url = ""
        if (param_company !== 'undefined' && param_location !== 'undefined' ){
            $url = "https://" + SPTenant + ".sharepoint.com/sites/" + SPSiteCollection + "/_api/web/lists/getbytitle('" + SPList + "')/items?$select=FileLeafRef,Art,Standort&$filter=((Firma eq '" + param_company +"') and (Standort eq '" + param_location + "'))";
        } 
        
        if (param_company !== 'undefined' && param_location === 'undefined' ){
            $url = "https://" + SPTenant + ".sharepoint.com/sites/" + SPSiteCollection + "/_api/web/lists/getbytitle('" + SPList + "')/items?$select=FileLeafRef,Art,Standort&$filter=(Firma eq '" + param_company +"')";
        }

        if (param_id !== 'undefined'){
            $url = "https://" + SPTenant + ".sharepoint.com/sites/" + SPSiteCollection + "/_api/web/lists/getbytitle('" + SPList + "')/items?$select=FileLeafRef,Art,Standort&$filter=(Roth_x002d_ID eq '" + param_id +"')";
        }
        
        await axios.get(
            $url,
            { headers: { Authorization: "Bearer " + tokenRef.current }
        }).then((response) => {
            setFilelist(response["data"]["value"])
            createLists(response["data"]["value"])
        }).catch((error) => {
            setErrorMsg("Kein Zugriff, Passwort falsch!");
        });
        
           
    } 

    const SPrefineList = async() => {
        var $url = "https://" + SPTenant + ".sharepoint.com/sites/" + SPSiteCollection + "/_api/web/lists/getbytitle('" + SPList + "')/items?$select=FileLeafRef,Art,Standort&$filter=("
        if (param_company !== null) {
            $url = $url + "(Firma eq '" + param_company +"')"
        }


        if (param_location === 'undefined' && location !== 'all' && location.length !== 0) {
            $url = $url + " and (Standort eq '" + location +"')"
        }

        console.log(docType)
        if (docType !== 'all' && docType.length !== 0 ) {
            $url = $url + " and (Art eq '" + docType +"')"
        }

        $url =  $url + ")"

        await axios.get(
            $url,
            { headers: { Authorization: "Bearer " + tokenRef.current }
        }).then((response) => {
            setFilelist(response["data"]["value"])
            }
        ).catch((error) => console.log(error));
    } 
    
    const SPfetchFile = async (file) => {
        await axios.get(
            // ATTANTION => GetFolderByServerRelativeUrl('Freigegebene%20Dokumente/... NICHT SPLIST !!!
            "https://" + SPTenant + ".sharepoint.com/sites/" + SPSiteCollection + "/_api/Web/GetFolderByServerRelativeUrl('Freigegebene%20Dokumente/')/Files('" + file.FileLeafRef + "')/$value", 
            {  
                headers: { Authorization: "Bearer " + tokenRef.current },
                responseType: 'blob'
            })
        .then((response) => {
            var url = window.URL.createObjectURL(new Blob([response.data], {type:'pdf'}));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.FileLeafRef);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    } 

    const createLists = (x) => {
        var locations = [];
        var docs = [];
        x.forEach(x => {
            locations.push(x.Standort)
            docs.push(x.Art)
        });
        setLocationList([...new Set(locations)]);
        setDocTypeList([...new Set(docs)]);
    }

    useEffect(() => {
        try {
            GetAccessToken();

        } catch (e) {
            console.error(e);
        }
    }, [param_company]);

    return (  
        <Box px={0} mt={5}>
            <Typography variant="h5" component="h1" mb={3}>
                Dachdokumentation
            </Typography>
            
                <Container>
                    <FormControl fullWidth className="form-dropdown">
                        <InputLabel id="label-location">Dokumentart</InputLabel>
                        <Select value={docType} label="Dokumentart" onChange={setDocType} >
                            <MenuItem key="Alle" value="all">Alle</MenuItem>
                            {docTypeList.map((x, key) => (
                                <MenuItem key={key} value={x}>{x}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {param_location === 'undefined' ?
                        <FormControl fullWidth className="form-dropdown">
                            <InputLabel id="label-location">Standort</InputLabel>
                            <Select value={location} label="Standort" onChange={setLocation} >
                                <MenuItem key="Alle" value="all" selected>Alle</MenuItem>
                                {locationList.map((x, key) => (
                                    <MenuItem key={key} value={x}>{x}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    : null}
                    <Box my={4}>
                        <Button variant="contained" onClick={SPrefineList} value="none">Filter anwenden</Button>
                    </Box>
                </Container>
            
            
            {/* Dachplan */}
            {filelist.find((x)=>x.Art === 'Dachplan') ?
            <Box mb={5} >
                <Container>
                    <Typography align="left" variant="h6" component="h6" mb={1}>Dachpläne</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ tableLayout: "fixed"}}>
                            <TableBody>
                                {filelist.map((x) => (
                                    x.Art === "Dachplan" ? 
                                        <TableRow key={x.Title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            <TableCell align="left" onClick={() => SPfetchFile(x)}>{x.FileLeafRef}</TableCell>
                                        </TableRow>
                                    : ""
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
            : ''}

            {/* Pruefbericht */}
            {filelist.find((x)=>x.Art === 'Pruefbericht') ?
                <Box mb={5} >
                    <Container>
                        <Typography align="left" variant="h6" component="h6" mb={1}>Prüfberichte</Typography> 
                        <TableContainer component={Paper}>
                            <Table sx={{ tableLayout: "fixed"}}>
                                <TableBody>
                                    {filelist.map((x) => (
                                        x.Art === "Pruefbericht" ? 
                                            <TableRow key={x.Title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell align="left" onClick={() => SPfetchFile(x)}>{x.FileLeafRef}</TableCell>
                                            </TableRow>
                                        : ""
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            : ''}

            {/* Montagedoku */}
            {filelist.find((x)=>x.Art === 'Montagedoku') ?
                <Box mb={5} >
                    <Container>
                        <Typography align="left" variant="h6" component="h6" mb={1}>Montagedokumentationen</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ tableLayout: "fixed"}}>
                                <TableBody>
                                    {filelist.map((x) => (
                                        x.Art === "Montagedoku" ? 
                                            <TableRow key={x.Title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell align="left" onClick={() => SPfetchFile(x)}>{x.FileLeafRef}</TableCell>
                                            </TableRow>
                                        : ""
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            : ''}

            {/* Dachwartungsprotokoll */}
            {filelist.find((x)=>x.Art === 'Dachwartungsprotokoll') ?
                <Box mb={5} >
                    <Container>
                        <Typography align="left" variant="h6" component="h6" mb={1}>Dachwartungsprotokolle</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ tableLayout: "fixed"}}>
                                <TableBody>
                                    {filelist.map((x) => (
                                        x.Art === "Dachwartungsprotokoll" ? 
                                            <TableRow key={x.Title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell align="left" onClick={() => SPfetchFile(x)}>{x.FileLeafRef}</TableCell>
                                            </TableRow>
                                        : ""
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            : ''}

            <Box mt={5}>
                <Typography variant="h5" component="h1" mb={3}>{errorMsg}</Typography>
            </Box>
            
        </Box>
    );
};

export default Home;


